import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { sendRequest } from 'features/main/MainSlice';
import { RootState } from 'app/store';
import Spinner from 'components/ui/spinner/Spinner';

import img_user from 'assets/images/icons/user.png';
import img_password from 'assets/images/icons/key.png';
import img_eye from 'assets/images/icons/eye.svg';
import img_eye_close from 'assets/images/icons/eye-close.svg';
import img_error from 'assets/images/icons/exclamation.svg';

import './AuthForm.scss';

export default function AuthForm(props: any) {

    const { isDesktop, userAgent } = props;

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const isLoading = useAppSelector((state: RootState) => state.main.isLoading);
    const language = useAppSelector((state: RootState) => state.utility.language);

    const [passwordType, setPasswordType] = useState('password');

    const togglePasswordType = (): void => {
        passwordType === 'password' ? setPasswordType('text') : setPasswordType('password');
    }

    const validationSchema = Yup.object({
        username: Yup.string().required(t('header.username_error')),
        password: Yup.string().required(t('header.password_error'))
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(sendRequest({
                isLoading: true,
                route: 'login',
                login: values.username.trim(),
                password: values.password.trim(),
                language: language,
                userAgent: userAgent
            }))
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} className='AuthFormWrapper'>

            <div className={`AuthForm ${isDesktop ? 'DesktopAuthForm' : ''}`}>

                <div className='FormInputContainer FormInputContainerUsername'>
                    <div className='FormIcon'>
                        <img src={img_user} alt='user' className='FormIconImage' />
                    </div>
                    <input
                        type='username'
                        name='username'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username}
                        className={`FormInput ${formik.touched.username && formik.errors.username && 'FormInputError'}`}
                        placeholder={t('header.username')}
                    />
                    {formik.touched.username && formik.errors.username && (
                        <img src={img_error} alt='error' className='ErrorImage' />
                    )}
                </div>

                <div className='FormInputContainer'>
                    <div className='FormIcon'>
                        <img src={img_password} alt='password' className='FormIconImage' />
                    </div>
                    <input
                        type={passwordType}
                        name='password'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        className={`FormInput ${formik.touched.password && formik.errors.password && 'FormInputError'}`}
                        placeholder={t('header.password')}
                    />
                    <div className={`AuthFormPasswordToggle ${formik.touched.password && formik.errors.password && 'AuthFormPasswordToggleError'}`} onClick={togglePasswordType}>
                        {passwordType === 'password' ? (
                            <img src={img_eye_close} alt='password' className='FormIconEyeImage' />
                        ) : (
                            <img src={img_eye} alt='password' className='FormIconEyeImage' />
                        )}
                    </div>
                    {formik.touched.password && formik.errors.password && (
                        <img src={img_error} alt='error' className='ErrorImage' />
                    )}
                </div>

            </div>

            <button className='AuthFormLoginButton' type='submit' disabled={isLoading ? true : false}>
                {isLoading ? <Spinner /> : <span>{t('header.login')}</span>}
            </button>

        </form>
    )
}