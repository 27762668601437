import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { formatCash } from 'features/helpers/general';
import { IoMdClose } from 'react-icons/io';
import MainView from '../shared/MainView';
import '../shared/Shared.scss';
import './Main.scss';

export default function Main(props: any) {

    const { handleClose, handleModalType } = props;

    const { t } = useTranslation();

    const { id, login, name, cash_bonus, currency, wager, words }: any = useAppSelector((state: RootState) => state.main.userData);
    const language: string = useAppSelector((state: RootState) => state.utility.language);

    return (
        <MainView>
            <div className='ProfileMain'>
                <h3 className='ProfileTitle'>{t('profile.title')}</h3>

                <div className='ProfileContainer'>

                    <div className='MainBox'>
                        <span>ID:</span>
                        <span>{id}</span>
                    </div>
                    <div className='MainBox'>
                        <span>{t('header.login')}:</span>
                        <span>{login}</span>
                    </div>
                    <div className='MainBox'>
                        <span>{t('profile.name')}:</span>
                        <span>{name}</span>
                    </div>

                    <div className='MainBox'>
                        <span>{t('profile.cashback')}:</span>
                        <span>{formatCash(language, currency, cash_bonus)}</span>
                    </div>

                    {wager.enable && (
                        <div className='MainBox'>
                            <span>{words.chargeable_balance}</span>
                            <span>{formatCash(language, currency, wager.chargeable)}</span>
                        </div>
                    )}

                </div>

                <button className='ProfileButtonBlue' onClick={() => handleModalType('password')}>
                    {t('profile.change_password')}
                </button>

                <button className='CloseButton' onClick={handleClose}>
                    <IoMdClose />
                </button>
            </div>
        </MainView>
    )
}