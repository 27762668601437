import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { isMobile, isIOS, useMobileOrientation } from 'react-device-detect';
import { useResizeObserver } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { sendRequest } from 'features/main/MainSlice';
import { setCurrentGame } from 'features/utility/UtilitySlice';
import img_finger from 'assets/images/icons/finger.svg';
import GameControls from 'components/gameWindow/GameControls/GameControls';
import GameFrame from 'components/gameWindow/GameFrame/GameFrame';
import CloseModal from 'components/gameWindow/CloseModal/CloseModal';
import 'components/gameWindow/GameWindow.scss';

export default function Game() {

    const ref = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const pageState: any = useLocation().state;

    const handleFullscreen = useFullScreenHandle();
    const { isLandscape } = useMobileOrientation();

    const dispatch = useAppDispatch();
    const isLogin: boolean = useAppSelector((state: RootState) => state.main.isLogin);
    const game: any = useAppSelector((state: RootState) => state.main.game);
    const token = useAppSelector((state: RootState) => state.main.token);
    const language = useAppSelector((state: RootState) => state.utility.language);

    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [isIOSFullscreen, setIOSFullscreen] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const { width = 0, height = 0 } = useResizeObserver({
        ref,
        box: 'border-box'
    });

    const handleClose = () => {
        setShowModal(true);
    }

    const handleCancel = () => {
        setShowModal(false);
    }

    const handleCloseGameWindow = () => {
        dispatch(sendRequest({
            route: 'close',
            session: game.data.session,
            token: token,
            language: language
        }));
        dispatch(setCurrentGame({}));
        navigate(pageState.page, { state: pageState.pageYOffset });
    }


    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
        if (isFullscreen) {
            handleFullscreen.exit();
        } else {
            handleFullscreen.enter();
        }
    }

    // iOS fullscreen
    useEffect(() => {
        if (isIOS && isLandscape) {
            if (window.screen.width === height) {
                setIOSFullscreen(true);
            } else {
                setIOSFullscreen(false);
            }
        }
    }, [isLandscape, height]);

    useEffect(() => {
        if (!game.isOpen || !isLogin) {
            navigate('/');
        }
    }, [isLogin, navigate]);

    return (
        <FullScreen handle={handleFullscreen}>

            {isIOS && isLandscape && <div className='iOS_Overlay' style={{
                visibility: isIOSFullscreen ? 'hidden' : 'visible'
            }}>
                <div className='FingerWrapper'>
                    <img src={img_finger} alt='scroll' className='Finger' />
                </div>
            </div>}

            <div ref={ref} className={`GameWindow ${isLandscape ? 'GameWindowLanscape' : ''}`}>
                <GameControls
                    game={game.data}
                    isIOS={isIOS}
                    toggleFullscreen={toggleFullscreen}
                    isFullscreen={isFullscreen}
                    handleClose={handleClose}
                    isLandscape={isLandscape}
                />

                <GameFrame url={game.data.url} />
            </div>

            {showModal && <CloseModal handleCancel={handleCancel} handleCloseGameWindow={handleCloseGameWindow} />}

        </FullScreen>
    )
}