import { useRef } from 'react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { useScrollLock } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { setShowModal } from 'features/utility/UtilitySlice';
import LoginForm from 'components/hoc/loginForm/LoginForm';
import Logout from './types/logout/Logout';
import Profile from 'components/profile/Profile';
import './Modal.scss';

export default function Modal(props: any) {

    const { userAgent } = props;

    const ref = useRef<HTMLDivElement>(null);
    const { contextSafe } = useGSAP({ scope: ref });

    const dispatch = useAppDispatch();
    const { type } = useAppSelector((state: RootState) => state.utility.showModal);
    const isLogin = useAppSelector((state: RootState) => state.main.isLogin);

    useScrollLock();

    useGSAP(() => {
        gsap.from('.Modal', {
            scale: 0.5,
            opacity: 0.3,
            duration: 0.3
        });
    });

    const handleClose = contextSafe(() => {
        gsap.to(ref.current, {
            scale: 0,
            opacity: 0,
            duration: 0.3,
            onComplete: () => {
                dispatch(setShowModal({ show: false, type: '' }));
            }
        })
    });

    const handleModalType = (type: string) => dispatch(setShowModal({ show: true, type: type }));

    const renderModal = (type: string) => {
        switch (type) {
            case 'login':
            case 'register':
                return <LoginForm userAgent={userAgent} handleClose={handleClose} />;

            case 'logout':
                return <Logout handleClose={handleClose} />

            case 'deposit':
            case 'withdraw':
            case 'profile':
            case 'gameHistory':
            case 'transactionHistory':
            case 'password':
            case 'bonuses':
                return <Profile type={type} handleClose={handleClose} handleModalType={handleModalType} isLogin={isLogin} />
            default:
                return <></>
        }
    }

    const isProfile = () => {
        switch (type) {
            case 'deposit':
            case 'withdraw':
            case 'profile':
            case 'gameHistory':
            case 'transactionHistory':
            case 'password':
            case 'bonuses':
                return true;
            default:
                return false;
        }
    }

    return (
        <div ref={ref} className={`Modal ${isProfile() ? 'ModalProfile' : ''}`}>
            {renderModal(type)}
        </div>
    )
}