import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ImSpinner2 } from 'react-icons/im';
import './Spinner.scss';

export default function Spinner() {

    useGSAP(() => {
        gsap.from('.Spinner', {
            scale: 0,
            duration: 0.3
        });

        gsap.to('.Spinner', {
            rotate: 360,
            duration: 1,
            ease: 'none',
            repeat: -1
        });
    });

    return (
        <ImSpinner2 className='Spinner' />
    )
}