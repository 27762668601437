import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import AuthForm from '../inputForm/AuthForm';
import './DesktopLoginForm.scss';

export default function DesktopLoginForm(props: any) {
    const { showModal, selectForm, closeForm, userAgent } = props;

    const ref = useRef<HTMLDivElement>(null)

    const { t } = useTranslation();

    useOnClickOutside(ref, closeForm);

    return (
        <div className='DesktopLogin'>
            <div ref={ref} className='DesktopLoginContainer'>

                <div className='DesktopLoginButtons'>
                    <button className={`Register ${showModal.type === 'register' ? 'ButtonActive' : ''}`} onClick={() => selectForm('register')}>{t('header.register')}</button>
                    <button className={`Login ${showModal.type === 'login' ? 'ButtonActive' : ''}`} onClick={() => selectForm('login')}>{t('header.login')}</button>
                </div>

                <AuthForm isDesktop={true} userAgent={userAgent} />

                <div className='FormToggle'>
                    <span>{t('header.not_member')}</span>
                    <button onClick={() => selectForm('register')}>
                        {t('header.register')}
                    </button>
                </div>
            </div>
        </div>
    )
}