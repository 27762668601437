import BannerNewsSection from "components/BannerNewsSection/BannerNewsSection";
import GameCategories from "components/GameCategories/GameCategories";
import Games from "components/Games/Games";

import './DesktopLayout.scss';
import DesktopFooter from "components/hoc/footer/desktop/DesktopFooter";

export default function DesktopLayout() {
    return (
        <>
            <section className='DesktopLayout'>
                <GameCategories />
                <div className='DesktopMain'>
                    <BannerNewsSection />
                    <Games />
                </div>
            </section>
            <DesktopFooter />
        </>
    )
}