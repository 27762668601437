
import './DesktopFooter.scss';

export default function DesktopFooter() {

    const YEAR = new Date().getFullYear();

    return (
        <footer className='Footer'>
            <span>&copy; {YEAR} PlayM8 All rights Reserved</span>
        </footer>
    )
}