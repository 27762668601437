import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { toast } from 'react-toastify';
import axios from 'axios';
import i18n from 'features/lang/i18n';
import { config } from 'app/config';
import { setJackpotHistory, setShowModal } from 'features/utility/UtilitySlice';

const API_VERSION = 'v1';
const newWindowParams = 'left=0,top=0,width=1280,height=720,menubar=no,toolbar=no,location=no,status=no,resizable=yes,scrollbars=no';
const { t } = i18n;
const { igs } = config;

export const initialState = {
    isTryAgain: false,
    token: '',
    isLogin: false,
    isLoading: false,
    isFirstTimeLoading: true,
    userData: {
        jackpots: [],
        currency: '',
        buttons: {
            changePassword: 1,
            exit: 1
        },
        news: [],
        bonuses: {},
        words: {},
        wheels: 0 // Wheel of Fortune
    },
    reconnect: {
        isConnecting: false,
        attempts: 0,
    },
    games: [],
    temporaryList: [],
    game: {
        isOpen: false,
        data: {}
    },
    isError: {
        show: false,
        type: ''
    },
    jackpotWinner: {
        isWinner: false,
        data: {}
    },
    fortuneWheel: {},
    lastJackpotWinners: [],
    attention: {
        isShow: false,
        data: {},
        game: {}
    },
    paymentsList: {},
    transactionHistory: [],
    gamesHistory: []
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setIsTryAgain: (state, action) => {
            state.isTryAgain = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setIsLogin: (state, action) => {
            state.isLogin = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsFirstTimeLoading: (state, action: PayloadAction<boolean>) => {
            state.isFirstTimeLoading = action.payload;
        },
        updateUserData: (state, action) => {
            state.userData = action.payload;
        },
        setRecconect: (state, action) => {
            state.reconnect = {
                isConnecting: action.payload.isConnecting,
                attempts: action.payload.attempts
            }
        },
        updateGamesList: (state, action) => {
            state.games = action.payload;
            state.temporaryList = action.payload.gameList;
        },
        setTemporaryList: (state, action) => {
            state.temporaryList = action.payload;
        },
        logout: (state) => {
            state.isLogin = false;
            state.token = '';
            state.userData = {
                jackpots: state.userData.jackpots,
                currency: state.userData.currency,
                buttons: state.userData.buttons,
                news: state.userData.news,
                bonuses: {},
                words: state.userData.words,
                wheels: 0
            };
            state.game = {
                isOpen: false,
                data: {}
            };
            state.fortuneWheel = {};
            state.lastJackpotWinners = [];
            state.attention = {
                isShow: false,
                data: {},
                game: {}
            };
            state.paymentsList = {}
        },
        setGame: (state, action) => {
            state.game = {
                isOpen: action.payload.isOpen,
                data: action.payload.data
            }
            state.isLoading = false;
        },
        setIsError: (state, action) => {
            state.isError = {
                show: action.payload.show,
                type: action.payload.type
            }
        },
        setJackpotWinner: (state, action) => {
            state.jackpotWinner = action.payload;
        },
        setFortuneWheel: (state, action) => {
            state.fortuneWheel = action.payload;
        },
        resetFortuneWheel: (state) => {
            state.userData = {
                ...state.userData,
                wheels: 0
            }
            state.fortuneWheel = {}
        },
        setLastJackpotWinners: (state, action) => {
            state.lastJackpotWinners = action.payload;
        },
        setAttention: (state, action) => {
            state.attention = {
                isShow: action.payload.isShow,
                data: action.payload.data,
                game: action.payload.game
            }
        },
        setPaymentsList: (state, action) => {
            state.paymentsList = action.payload
        },
        setTransactionHistory: (state, action) => {
            state.transactionHistory = action.payload
        },
        setGamesHistory: (state, action) => {
            state.gamesHistory = action.payload
        }
    }
});

export const {
    setIsTryAgain,
    setToken,
    setIsLogin,
    setIsLoading,
    setIsFirstTimeLoading,
    updateUserData,
    setRecconect,
    updateGamesList,
    setTemporaryList,
    logout,
    setGame,
    setIsError,
    setJackpotWinner,
    setFortuneWheel,
    resetFortuneWheel,
    setLastJackpotWinners,
    setAttention,
    setPaymentsList,
    setTransactionHistory,
    setGamesHistory
} = mainSlice.actions;


export const mainSelector = (state: RootState) => state.main;

interface IUserInfo {
    token: string,
    first?: boolean,
    casino?: boolean,
    getPaymentList?: boolean,
    attempts?: number,
    language?: string
}

export const getUserData = ({ token, first = false, casino = false, getPaymentList = false, attempts, language = 'en' }: IUserInfo) => async (dispatch: any) => {

    try {
        const response = await axios.post(`/api/${API_VERSION}/info`, {
            token: token,
            first: first,
            language: language
        });

        if (response.data.status === 'success') {

            dispatch(updateUserData(response.data.content));

            // dispatch(sendRequest({
            //     route: 'jackpotinfo',
            //     token: token,
            //     userId: response.data.content.id
            // }));

            // CAUSE ErrorCaptureStackTrace(err); TODO
            // dispatch(sendRequest({
            //     route: 'history',
            //     token: token,
            //     language: language
            // }));


            // Get Payment List on Login
            //if (casino && getPaymentList) {
            // dispatch(casinoGetPayment({ token: data.token, operation: '', currency: response.data.content.currency }))
            //}

            //if (attempts !== undefined && attempts > 0) {
            // dispatch(setRecconect({
            //     isConnecting: false,
            //     attempts: 0,
            // }))
            //}

        } else if (response.data.status === 'fail') {
            // console.log(response.data);
            dispatch(setIsError({
                show: true,
                type: response.data.errorCode
            }));
        }

    } catch (err) {
        if (attempts !== undefined && attempts <= 5) {
            // dispatch(setRecconect({
            //     isConnecting: true,
            //     attempts: data.attempts++,
            // }))
            dispatch(getUserData({ token: token, attempts: attempts++ }));
        } else {
            // dispatch(setNetworkError({
            //     show: true,
            //     type: ''
            // }))
        }
    }
}

export const sendRequest = (data: any) => async (dispatch: any) => {

    if (data.isLoading) dispatch(setIsLoading(true));

    if (data.route === 'init') dispatch(setIsFirstTimeLoading(true));

    if (data.route === 'close') dispatch(setGame({ isOpen: false, data: {} }));

    if (data.route === 'logout') dispatch(logout());

    // console.log(data);

    try {
        const response = await axios.post(`/api/${API_VERSION}/${data.route}`, { ...data });

        // console.log('response', response);
        if (data.isLoading) dispatch(setIsLoading(false));

        if (response.data.status === 'success') {

            switch (data.route) {
                case 'update':
                    toast.success(t('password.success').toString());
                    break;

                case 'init':
                    dispatch(updateUserData(response.data.content[0]));
                    dispatch(updateGamesList(response.data.content[1]));
                    dispatch(setIsFirstTimeLoading(false));
                    break;

                case 'login':
                case 'register':
                    dispatch(updateUserData(response.data.content[0]));
                    dispatch(updateGamesList(response.data.content[1]));
                    dispatch(setToken(response.data.token));
                    toast.success(t('general.success').toString());
                    dispatch(setIsLogin(true));
                    dispatch(setShowModal({ show: false, type: '' }));
                    break;

                case 'open':
                    if (data.isMobile || !igs) {
                        dispatch(setGame({
                            isOpen: true,
                            data: response.data.content
                        }));
                    } else {
                        window.open(response.data.content.url, response.data.content.name, newWindowParams);
                    }

                    // Close attention modal if player agree to continue and lose all bonuses
                    if (data.isAttentionShow) {
                        dispatch(setAttention({
                            isShow: false,
                            data: {},
                            game: {}
                        }));
                    }
                    break;
                case 'jackpotinfo':
                    response.data.content.map((jackpot: any) => {
                        if (jackpot.id !== null && jackpot.user != data.userId) {
                            dispatch(setJackpotHistory(jackpot))
                        }
                    });
                    break;

                case 'code':
                    toast.success(t('general.success').toString());
                    break;

                case 'getWheel':
                    dispatch(setFortuneWheel(response.data.content.values));
                    // return response.data.content.values; TODO
                    break;

                case 'getWheelResult':
                    // TODO
                    break;

                case 'getWheelUrl':
                    dispatch(setFortuneWheel(response.data.url));
                    break;

                case 'history':
                    dispatch(setLastJackpotWinners(response.data.url.content));
                    break;

                case 'paymentsList':
                    dispatch(setPaymentsList(response.data.content));
                    break;

                case 'transactionHistory':
                    dispatch(setTransactionHistory(response.data.content));
                    break;

                case 'gamesHistory':
                    dispatch(setGamesHistory(response.data.content));
                    break;

                default:
                    if (response.data.token) dispatch(setToken(response.data.token)); // Update expired token if new token received
                    break;
            }

        } else if (response.data.status === 'fail' || response.data.status === 'error') {

            switch (data.route) {
                case 'init':
                    dispatch(setIsError({
                        show: true,
                        type: response.data.errorCode
                    }));
                    break;
                case 'login':
                case 'code':
                    toast.error(response.data.error);
                    break;
                case 'open':
                    if (data.demo == 1) {
                        toast.error(t('game.demo_error').toString());
                    } else if (response.data.errorCode == 'attention_error') {
                        dispatch(setAttention({
                            isShow: true,
                            data: response.data.attention,
                            game: {
                                gameId: data.gameId,
                                demo: data.demo,
                                isMobile: data.isMobile
                            }
                        }));
                    } else {
                        toast.error(response.data.error);
                        dispatch(logout());
                    }
                    break;
                case 'getWheel':
                case 'getWheelResult':
                case 'getWheelUrl':
                    dispatch(resetFortuneWheel());
                    toast.error(response.data.error);
                    break;

                case 'register':
                case 'update':
                case 'paymentsList':
                case 'paymentRequest':
                case 'transactionHistory':
                case 'gamesHistory':
                    toast.error(response.data.error);
                    break;

                default:
                    toast.error(response.data.error);
                    dispatch(logout());
                    break;
            }
        }


    } catch (error) {
        if (data.isLoading) dispatch(setIsLoading(false));
        console.error('sendRequest error', error);
    }
}

export default mainSlice.reducer;