import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { IoPlayOutline } from 'react-icons/io5';
import Spinner from 'components/ui/spinner/Spinner';
import './Game.scss';

export default function GamePlayButton(props: any) {
    const { game, sendOpenGame, isLoading } = props;

    useGSAP(() => {
        gsap.from('.PlayButton', { y: '100%', opacity: 0, duration: 0.3 });
    });

    return (
        <div className='GameHover'>
            <div className={`PlayButton ${isLoading && 'PlayButtonLoading'}`} onClick={() => sendOpenGame({ game })}>
                {isLoading ? <Spinner /> : <IoPlayOutline className='PlayIcon' />}
            </div>
        </div>
    )
}