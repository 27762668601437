import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { useTranslation } from 'react-i18next';
import { combineObjectKeys } from 'features/helpers/general';
import { setTemporaryList } from 'features/main/MainSlice';
import { findObject } from 'features/helpers/gamesList';
import { setGameCategory } from 'features/utility/UtilitySlice';
import './GameCategories.scss';

import img_live from 'assets/images/categories/live.png';
import img_slots from 'assets/images/categories/slots.png';
import img_fish from 'assets/images/categories/fish.png';
import img_fast from 'assets/images/categories/fast.png';
import img_top from 'assets/images/categories/top.png';
import img_new from 'assets/images/categories/new.png';
import img_favorite from 'assets/images/categories/favorite.png';
import img_all from 'assets/images/categories/all.png';
import img_poker from 'assets/images/categories/poker.png';

const setImage = (category: string) => {
    switch (category) {
        case 'live_dealers':
            return img_live;
        case 'slots':
            return img_slots;
        case 'fishing_games':
            return img_fish;
        case 'fast_games':
            return img_fast;
        case 'top':
            return img_top;
        case 'new':
            return img_new;
        case 'favorite':
            return img_favorite;
        case 'all':
            return img_all;
        case 'poker':
            return img_poker;
        default:
            return img_all;
    }
}

export default function GameCategories() {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { categories, specials, games } = useAppSelector((state: any) => state.main.games);
    const gamesFavorite = useAppSelector((state: RootState) => state.utility.gamesFavorite);
    const gamesHistory = useAppSelector((state: RootState) => state.utility.gamesHistory);
    const gameCategory = useAppSelector((state: RootState) => state.utility.gameCategory);

    const scrollToTop = (): void => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const setCategoryTitle = (title: string) => {
        switch (title) {
            case 'slots':
                return t('category.slots');
            case 'live_dealers':
                return t('category.live_dealers');
            case 'fishing_games':
                return t('category.fishing_games');
            case 'fast_games':
                return t('category.fast_games');
            case 'top':
                return t('category.top');
            case 'new':
                return t('category.new');
            case 'favorite':
                return t('category.favorite');
            case 'all':
                return t('category.all');
            default:
                return 'PlayM8';
        }
    }

    const handleCategory = (category: string): void => {
        switch (category) {
            case 'favorite':
                dispatch(setTemporaryList(gamesFavorite));
                break;
            case 'history':
                dispatch(setTemporaryList(gamesHistory));
                break;
            case 'slots':
                dispatch(setTemporaryList([]));
                break;
            default:
                dispatch(setTemporaryList(findObject(category, games, categories, specials)));
                break;
        }
    }

    const setTemporaryGamesList = (category: any) => {
        scrollToTop();
        dispatch(setGameCategory(category));
        handleCategory(category);
    }

    return (
        <div className='Categories'>
            {combineObjectKeys(categories, specials).map((category: any, i: any) => (
                <button key={i} className={`Button ${gameCategory === category ? 'Selected' : ''}`} onClick={() => setTemporaryGamesList(category)}>
                    <img src={setImage(category)} alt={category} />
                    <span>{setCategoryTitle(category)}</span>
                </button>
            ))}
        </div>
    )
}