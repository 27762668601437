import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { setLanguage } from 'features/utility/UtilitySlice';
import th_img from 'assets/images/languages/th.svg';
import en_img from 'assets/images/languages/en.svg';
import './LanguageButton.scss';
import i18n, { changeLanguage } from 'features/lang/i18n';

export default function LanguageButton() {
    const dispatch = useAppDispatch();

    const handleLanguageChange = () => {
        const code = i18n.resolvedLanguage === 'th' ? 'en' : 'th';
        dispatch(setLanguage(code));
        changeLanguage(code);
    }

    return (
        <button className='LanguageButton' onClick={handleLanguageChange}>
            {i18n.resolvedLanguage === 'th' ? (
                <img src={en_img} alt='flag' className='LanguageButtonImage' />
            ) : (
                <img src={th_img} alt='flag' className='LanguageButtonImage' />
            )}
        </button>
    )
}