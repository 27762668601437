import { useAppSelector } from 'app/hooks';
import Marquee from 'react-fast-marquee'
import { RootState } from 'app/store';
import img_announce from 'assets/images/news/announce.png';

import './News.scss';

export default function News() {

    const stateNews = useAppSelector((state: RootState) => state.main.userData.news);

    return (
        <div className='News'>
            <div className='Container'>
                <img src={img_announce} alt='announce' className='Announce' />
                <div className='Wrapper'>
                    <Marquee gradient={false}>
                        {
                            stateNews.length > 0 ? stateNews.map((text: any, i: any) =>
                                <span key={i} className='Text'>{text}</span>) :
                                <span className='Text'>ยินดีต้อนรับสู่การเล่นที่ PlayM8</span>
                        }
                    </Marquee>
                </div>
            </div>
        </div>
    )
}