import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { sendRequest } from 'features/main/MainSlice';
import { formatCash } from 'features/helpers/general';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Spinner from 'components/ui/spinner/Spinner';
import MainView from '../shared/MainView';
import { IoMdClose } from 'react-icons/io';
import '../shared/Shared.scss';
import './Withdraw.scss';

import { config } from 'app/config';
const { payments } = config;

interface FormValues {
    bankCardNum: string;
    bankUserName: string;
    bankName: string;
    amount: number;
}

const initialValues: FormValues = {
    bankCardNum: '',
    bankUserName: '',
    bankName: '',
    amount: 300
};

export default function Withdraw(props: any) {
    const { handleClose } = props;
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const token: string = useAppSelector((state: RootState) => state.main.token);
    const isLoading: boolean = useAppSelector((state: RootState) => state.main.isLoading);
    const language = useAppSelector((state: RootState) => state.utility.language);
    const { currency, cash, wager }: any = useAppSelector((state: RootState) => state.main.userData);

    const validationSchema = Yup.object({
        bankCardNum: Yup.string().required(t('payment.bank_account_empty')),
        bankUserName: Yup.string().required(t('payment.bank_account_name_empty')),
        bankName: Yup.string().required(t('payment.select_bank')),
        amount: Yup.number()
            .min(300, t('payment.less_than'))
            .max(50000, t('payment.greater_than'))
            .required(t('payment.empty'))
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(sendRequest({
                isLoading: true,
                route: 'paymentRequest',
                token: token,
                operation: 'out',
                provider: payments.withdraw.id,
                fields: {
                    bankCardNum: values.bankCardNum,
                    bankUserName: values.bankUserName,
                    bankName: values.bankName,
                    amount: values.amount
                }
            }));
        },
    });

    return (
        <MainView>

            <div className='ProfileMain'>
                <h3 className='ProfileTitle'>{t('footer.withdraw')}</h3>

                <div className='ProfileContainer'>

                    <p>{t('payment.fill_form')}</p>
                    <p>{t('payment.mandatory')}</p>

                    <form className='WithdrawForm' onSubmit={formik.handleSubmit}>
                        {payments.withdraw.fields.map((field: any, i: any) => {
                            if (field.type === 'select') {
                                return (
                                    <Fragment key={i}>
                                        <select
                                            name={field.name}
                                            onChange={formik.handleChange}
                                            value={formik.values[field.name as keyof FormValues]}
                                            onBlur={formik.handleBlur}
                                            className={`WithdrawFormSelect ${formik.touched[field.name as keyof FormValues] && formik.errors[field.name as keyof FormValues] && 'WithdrawInputError'}`}
                                        >
                                            <option value=''>{t('payment.select_bank')}</option>
                                            {Object.entries(field.options).map(([key, value]: any) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))}
                                        </select>
                                        {formik.touched[field.name as keyof FormValues] && formik.errors[field.name as keyof FormValues] && (
                                            <span className='WithdrawFormError'>{formik.errors[field.name as keyof FormValues]}</span>
                                        )}
                                    </Fragment>
                                )
                            } else {
                                return (
                                    <Fragment key={i}>
                                        <input
                                            name={field.name}
                                            placeholder={t([`payment.${field.name}`])}
                                            onChange={formik.handleChange}
                                            value={formik.values[field.name as keyof FormValues]}
                                            type={field.type === 'string' ? 'text' : 'number'}
                                            onBlur={formik.handleBlur}
                                            className={`${formik.touched[field.name as keyof FormValues] && formik.errors[field.name as keyof FormValues] && 'WithdrawInputError'}`}
                                        />
                                        {formik.touched[field.name as keyof FormValues] && formik.errors[field.name as keyof FormValues] && (
                                            <span className='WithdrawFormError'>{formik.errors[field.name as keyof FormValues]}</span>
                                        )}
                                        {field.name === 'amount' && (
                                            <>
                                                <div className='AmountRequirements'>
                                                    {/* USER BALANCE */}
                                                    <span>{t('profile.balance')}:</span>
                                                    <span>{wager ? formatCash(language, currency, wager.chargeable) : formatCash(language, currency, cash)}</span>
                                                </div>
                                                <div className='AmountRequirements'>
                                                    {/* SYSTEM MIN AMOUNT */}
                                                    <span>{t('payment.min')}:</span>
                                                    <span>{formatCash(language, currency, field.min)}</span>
                                                </div>
                                                <div className='AmountRequirements'>
                                                    {/* SYSTEM MAX AMOUNT */}
                                                    <span>{t('payment.max')}:</span>
                                                    <span>{formatCash(language, currency, field.max)}</span>
                                                </div>
                                            </>
                                        )}
                                    </Fragment>
                                )
                            }
                        })}
                        <button type='submit' disabled={isLoading ? true : false}>
                            {isLoading ? <Spinner /> : t('footer.withdraw')}
                        </button>
                    </form>

                </div>

                <button className='CloseButton' onClick={handleClose}>
                    <IoMdClose />
                </button>

            </div>

        </MainView>
    )
}