export function matchGameIds(ids: string[], games: any) {
    const result: any = [];
    
    ids.forEach(id => {
        if (games.hasOwnProperty(id)) {
            result.push(games[id]);
        }
    });

    return result;
}

export function findObject(key: any, games: any, ...objects: any) {
    let categoryObject;

    for (const obj of objects) {
        if (obj.hasOwnProperty(key)) {
            categoryObject = obj;
            break;
        }
    }

    if (!categoryObject) {
        console.error('The key was not found in category');
        return [];
    }

    return matchGameIds(categoryObject[key]['ids'], games);
}