import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import th from './locales/th/translation.json';
import en from './locales/en/translation.json';

const resources = {
    th: {
        translation: th,
    },
    en: {
        translation: en,
    },
};

export const userLanguage = navigator.language;

export const changeLanguage = (language: any) => {
    i18n
        .changeLanguage(language)
        .then((t) => {
            t(language);
        })
        .catch((err) => console.log(`Error: ${err}`));
};

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources: resources,
    fallbackLng: 'th',
    lng: userLanguage,
    defaultNS: 'translation',
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
});

export default i18n;