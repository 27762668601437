import './GameFrame.scss';

export default function GameFrame(props: any) {
    const { url } = props;

    return (
        <div
            className='GameFrame'
            dangerouslySetInnerHTML={{
                __html: `
                <iframe 
                    id='frame' 
                    src=${url} 
                    style='width:100%; height:100%; left:0; top:0;border:none' 
                    allow='autoplay'
                    autofocus
                ></iframe>
            ` }}
        />
    )
}