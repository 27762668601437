import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import './Shared.scss';

export default function MainView(props: any) {
    const { children } = props;

    useGSAP(() => {
        gsap.from('.MainView', {
            scale: 0.9,
            opacity: 0.3,
            duration: 0.3
        });
    });

    return (
        <div className='MainView'>
            {children}
        </div>
    )
} 