import { useTranslation } from 'react-i18next';
import MainView from '../shared/MainView';
import { IoMdClose } from 'react-icons/io';
import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import Countdown from 'react-countdown';
import '../shared/Shared.scss';
import './Bonuses.scss';

export default function Bonuses(props: any) {
    const { handleClose } = props;
    const { t } = useTranslation();

    const { bonuses } = useAppSelector((state: RootState) => state.main.userData);

    const styles: any = {};

    return (
        <MainView>
            <div className='ProfileMain'>
                <h3 className='ProfileTitle'>{t('header.bonuses')}</h3>

                <div className='ProfileContainer'>
                    {Object.entries(bonuses).map(([key, bonus]: any) => {
                        if (key === 'wager') {
                            return (
                                <div key={key} className='BonusesContainer'>
                                    {Object.entries(bonus.main).map(([key, wager]: any) => {
                                        if (key !== 'status' && key !== 'chargeable') {
                                            return (
                                                <div key={key} className={styles.info_div}>
                                                    {wager.map((info: any, i: number) => <span key={i}>{info.value}</span>)}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            )
                        } else if (key === 'happy_hours') {
                            return (
                                <div key={key} className='BonusesContainer'>
                                    {bonus.main.info.map((info: any, i: number) => {
                                        if (info.tag === 'timer') {
                                            return <span key={i}><Countdown key='timer' date={Date.now() + Number(info.value) * 1000} daysInHours={true} /></span>
                                        } else {
                                            return <span key={i}>{info.value}</span>
                                        }
                                    })}
                                </div>
                            )
                        } else if (key === 'promocodes') {
                            return;
                        } else if (key === 'freespins') {
                            return (
                                <div key={key} className='BonusesContainer'>
                                    {bonus.main.count.map((info: any, i: number) => <span key={i}>{info.value}</span>)}
                                </div>
                            )
                        } else {
                            return (
                                <div key={key} className='BonusesContainer'>

                                    {/* CASHBACK */}
                                    {key === 'cashback' && bonus.main.amount.map((info: any, i: number) => <span key={i}>{info.value}</span>)}

                                    {/* ACCUMULATED BONUS, FREE SPINS WHEEL */}
                                    {(key === 'cumulative' || key === 'cumulative_wheel') && bonus.main.progress.map((info: any, i: number) => info.tag !== 'progress' && <span key={i}>{info.value}</span>)}

                                    {/* Fortune Wheel */}
                                    {key === 'wheel' && bonus.main.info.map((info: any, i: number) => <span key={i}>{info.value}</span>)}

                                </div>
                            )
                        }
                    })}
                </div>


                <button className='CloseButton' onClick={handleClose}>
                    <IoMdClose />
                </button>
            </div>
        </MainView>
    )
}