import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainView from '../shared/MainView';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import { sendRequest } from 'features/main/MainSlice';
import '../shared/Shared.scss';
import './Password.scss';
import Spinner from 'components/ui/spinner/Spinner';

interface FormValues {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const initialValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
};

export default function Password(props: any) {
    const { handleClose, handleModalType } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { id }: any = useAppSelector((state: RootState) => state.main.userData);
    const token = useAppSelector((state: RootState) => state.main.token);
    const language = useAppSelector((state: RootState) => state.utility.language);
    const isLoading = useAppSelector((state: RootState) => state.main.isLoading);

    const [showPasswords, setShowPasswords] = useState({
        oldPassword: false,
        newPassword: false,
        confirmNewPassword: false
    });

    const inputFields = [
        {
            value: 'oldPassword',
            title: t('profile.old_password')
        },
        {
            value: 'newPassword',
            title: t('profile.new_password')
        },
        {
            value: 'confirmNewPassword',
            title: t('profile.confirm_new_password')
        },
    ];

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required(t('profile.required')),
        newPassword: Yup.string().required(t('profile.required')),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], t('profile.not_match')).required(t('profile.required'))
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(sendRequest({
                isLoading: true,
                route: 'update',
                token: token,
                oldPassword: values.oldPassword.trim(),
                newPassword: values.newPassword.trim(),
                language: language
            }));
        }
    });

    const togglePasswordVisibility = (field: keyof typeof showPasswords) => {
        setShowPasswords(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    return (
        <MainView>
            <div className='ProfilePassword'>
                <h3 className='ProfileTitle'>{t('profile.change_password')}</h3>

                <div className='ProfileContainer'>

                    <div>
                        <span>ID:</span>
                        <span>{id}</span>
                    </div>

                    <form onSubmit={formik.handleSubmit}>

                        {inputFields.map((field) => (
                            <Fragment key={field.value}>
                                <div className={`InputContainer ${formik.touched[field.value as keyof FormValues] && formik.errors[field.value as keyof FormValues] && 'InputContainerError'}`}>
                                    <span>{field.title}</span>
                                    <input
                                        type={showPasswords[field.value as keyof FormValues] ? 'text' : 'password'}
                                        name={field.value}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values[field.value as keyof FormValues]}
                                        className={`FormInput ${formik.touched[field.value as keyof FormValues] && formik.errors[field.value as keyof FormValues] && 'FormInputError'}`}
                                    />
                                    <div
                                        className={`AuthFormPasswordToggle ${formik.touched[field.value as keyof FormValues] && formik.errors[field.value as keyof FormValues] && 'AuthFormPasswordToggleError'}`}
                                        onClick={() => togglePasswordVisibility(field.value as keyof typeof showPasswords)}
                                    >
                                        {showPasswords[field.value as keyof FormValues] ? (
                                            <IoMdEye className='FormIconEyeImage' />
                                        ) : (
                                            <IoMdEyeOff className='FormIconEyeImage' />
                                        )}
                                    </div>
                                </div>
                                {formik.touched[field.value as keyof FormValues] && formik.errors[field.value as keyof FormValues] && (
                                    <p className='ErrorMessage'>{formik.errors[field.value as keyof FormValues]}</p>
                                )}
                            </Fragment>
                        ))}

                        <div className='ProfilePasswordButtons'>
                            <button type='submit' className='ProfileButtonBlue ProfilePasswordButton'>
                                {isLoading ? <Spinner /> : t('profile.submit')}
                            </button>
                            <button type='button' className='ProfileButtonBlue ProfilePasswordButtonCancel' onClick={() => handleModalType('profile')}>
                                {t('game.cancel')}
                            </button>
                        </div>

                    </form>

                </div>

                <button className='CloseButton' onClick={handleClose}>
                    <IoMdClose />
                </button>

            </div>
        </MainView>
    )
}