import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { isObjEmpty, removeSuffix } from 'features/helpers/general';
import './Labels.scss';

import ainsworth from 'assets/images/providers/ainsworth.png';
import altente from 'assets/images/providers/altente.png';
import amatic from 'assets/images/providers/amatic.png';
import apex from 'assets/images/providers/apex.png';
import apollo from 'assets/images/providers/apollo.png';
import aristocrat from 'assets/images/providers/aristocrat.png';
import bally from 'assets/images/providers/bally.png';
import bingo from 'assets/images/providers/bingo.png';
import booming from 'assets/images/providers/booming.png';
import caleta from 'assets/images/providers/caleta.png';
import cq9 from 'assets/images/providers/cq9.png';
import dlv from 'assets/images/providers/dlv.png';
import egt from 'assets/images/providers/egt.png';
import evolution_games from 'assets/images/providers/evolution_games.png';
import fast_games from 'assets/images/providers/fast_games.png';
import firekirin from 'assets/images/providers/firekirin.png';
import fishing from 'assets/images/providers/fishing.png';
import gclub from 'assets/images/providers/gclub.png';
import habanero from 'assets/images/providers/habanero.png';
import igrosoft from 'assets/images/providers/igrosoft.png';
import igt from 'assets/images/providers/igt.png';
import inbet_slots from 'assets/images/providers/inbet_slots.png';
import jetx from 'assets/images/providers/jetx.png';
import kajot from 'assets/images/providers/kajot.png';
import live_betting from 'assets/images/providers/live_betting.png';
import live_dealers from 'assets/images/providers/live_dealers.png';
import merkur from 'assets/images/providers/merkur.png';
import microgaming from 'assets/images/providers/microgaming.png';
import netent from 'assets/images/providers/netent.png';
import novomatic from 'assets/images/providers/novomatic.png';
import pgsoft from 'assets/images/providers/pgsoft.png';
import platipus from 'assets/images/providers/platipus.png';
import playngo from 'assets/images/providers/playngo.png';
import playson from 'assets/images/providers/playson.png';
import pragmatic from 'assets/images/providers/pragmatic.png';
import quickspin from 'assets/images/providers/quickspin.png';
import red_tiger from 'assets/images/providers/red_tiger.png';
import rubyplay from 'assets/images/providers/rubyplay.png';
import scientific_games from 'assets/images/providers/scientific_games.png';
import spribe from 'assets/images/providers/spribe.png';
import table_games from 'assets/images/providers/table_games.png';
import tomhorn from 'assets/images/providers/tomhorn.png';
import tvbet from 'assets/images/providers/tvbet.png';
import vegas from 'assets/images/providers/vegas.png';
import wazdan from 'assets/images/providers/wazdan.png';
import yggdrasil from 'assets/images/providers/yggdrasil.png';
import zitro from 'assets/images/providers/zitro.png';
import { useEffect, useRef } from 'react';

const gameLables = [
    {
        label: 'ainsworth',
        img: ainsworth
    },
    {
        label: 'altente',
        img: altente
    },
    {
        label: 'amatic',
        img: amatic
    },
    {
        label: 'apex',
        img: apex
    },
    {
        label: 'apollo',
        img: apollo
    },
    {
        label: 'aristocrat',
        img: aristocrat
    },
    {
        label: 'bally',
        img: bally
    },
    {
        label: 'bingo',
        img: bingo
    },
    {
        label: 'booming',
        img: booming
    },
    {
        label: 'caleta',
        img: caleta
    },
    {
        label: 'cq9',
        img: cq9
    },
    {
        label: 'dlv',
        img: dlv
    },
    {
        label: 'egt',
        img: egt
    },
    {
        label: 'evolution_games',
        img: evolution_games
    },
    {
        label: 'fast_games',
        img: fast_games
    },
    {
        label: 'fast_games',
        img: fast_games
    },
    {
        label: 'firekirin',
        img: firekirin
    },
    {
        label: 'fishing',
        img: fishing
    },
    {
        label: 'gclub',
        img: gclub
    },
    {
        label: 'habanero',
        img: habanero
    },
    {
        label: 'igrosoft',
        img: igrosoft
    },
    {
        label: 'igt',
        img: igt
    },
    {
        label: 'inbet_slots',
        img: inbet_slots
    },
    {
        label: 'jetx',
        img: jetx
    },
    {
        label: 'jetx',
        img: jetx
    },
    {
        label: 'kajot',
        img: kajot
    },
    {
        label: 'live_betting',
        img: live_betting
    },
    {
        label: 'live_dealers',
        img: live_dealers
    },
    {
        label: 'merkur',
        img: merkur
    },
    {
        label: 'microgaming',
        img: microgaming
    },
    {
        label: 'netent',
        img: netent
    },
    {
        label: 'novomatic',
        img: novomatic
    },
    {
        label: 'pgsoft',
        img: pgsoft
    },
    {
        label: 'platipus',
        img: platipus
    },
    {
        label: 'playngo',
        img: playngo
    },
    {
        label: 'playson',
        img: playson
    },
    {
        label: 'pragmatic',
        img: pragmatic
    },
    {
        label: 'quickspin',
        img: quickspin
    },
    {
        label: 'red_tiger',
        img: red_tiger
    },
    {
        label: 'rubyplay',
        img: rubyplay
    },
    {
        label: 'scientific_games',
        img: scientific_games
    },
    {
        label: 'spribe',
        img: spribe
    },
    {
        label: 'table_games',
        img: table_games
    },
    {
        label: 'tomhorn',
        img: tomhorn
    },
    {
        label: 'tvbet',
        img: tvbet
    },
    {
        label: 'vegas',
        img: vegas
    },
    {
        label: 'wazdan',
        img: wazdan
    },
    {
        label: 'yggdrasil',
        img: yggdrasil
    },
    {
        label: 'zitro',
        img: zitro
    },
]

function excludeProviders(labels: any, gameCategory: any) {
    const notSlots = ['live_betting', 'bingo', 'fast_games', 'fishing', 'jetx', 'pragmatic_live', 'spribe', 'table_games', 'zitro'];
    if (gameCategory === 'slots') {
        return Object.keys(labels)
            .filter(key => !notSlots.includes(key))
            .reduce((result: any, key) => {
                result[key] = labels[key];
                return result;
            }, {});
    } else {
        return labels;
    }
}

export default function Labels(props: any) {
    const { labels, sortTemporaryList } = props;
    const gameCategory = useAppSelector((state: RootState) => state.utility.gameCategory);
    const renderLabels = excludeProviders(labels, gameCategory);

    const ref = useRef<HTMLDivElement>(null);
    const { contextSafe } = useGSAP({ scope: ref });

    const handleAnimation = contextSafe(() => {
        gsap.from('.LabelButton', { opacity: 0, y: '50px', duration: 0.5, stagger: 0.1 });
    });

    useEffect(() => {
        handleAnimation();
    }, [gameCategory]);

    return (
        <div ref={ref} className='GameLabels'>
            {renderLabels && !isObjEmpty(renderLabels) && Object.entries(renderLabels).filter(([key, _]) => gameLables.some(label => label.label === key)).map(([key, value]: any) => {
                const matchingLabel = gameLables.find(label => label.label === key);
                if (matchingLabel) {
                    return (
                        <button key={key} onClick={() => sortTemporaryList(key)} className='LabelButton'>
                            <img
                                className='GameLabelImage'
                                src={matchingLabel.img}
                                alt={value.title}
                            />
                            <span>{removeSuffix(value.title)}</span>
                        </button>
                    );
                }
                return null;
            })}
        </div>
    )
}