import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { useTranslation } from 'react-i18next';
import './CloseModal.scss';

export default function CloseModal(props: any) {

    const { handleCancel, handleCloseGameWindow } = props;

    const { t } = useTranslation();

    useGSAP(() => {
        gsap.from('.CloseModal', {
            scale: 0.5,
            opacity: 0.3,
            duration: 0.3
        });
    });

    return (
        <div className='CloseModal'>
            <h4>{t('game.question')}</h4>
            <div>
                <button className='Cancel' onClick={handleCancel}>{t('game.cancel')}</button>
                <button className='Confirm' onClick={handleCloseGameWindow}>{t('game.confirm')}</button>
            </div>
        </div>
    )
}