import { useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import img_placeholder from 'assets/images/game/placeholder.jpg';
import img_fs from 'assets/images/game/fs.png';
import GamePlayButton from './GamePlayButton';
import './Game.scss';

interface GameProps {
    game: any;
    selectedId: number;
    setSelectedId: (id: number) => void;
    sendOpenGame: (game: any) => void;
    isLoading: boolean;
}

const Game: React.FC<GameProps> = ({ game, selectedId, setSelectedId, sendOpenGame, isLoading }) => {

    const refContainer = useRef<HTMLDivElement>(null);
    const { contextSafe } = useGSAP({ scope: refContainer });

    const imageUrl = game.img.replace('game_img', 'game_img_4');

    const [loaded, setLoaded] = useState<boolean>(false);

    const { isIntersecting, ref } = useIntersectionObserver({
        threshold: 0.5,
        freezeOnceVisible: true
    });

    const handleSelect = (): void => {
        setSelectedId(game.id)
    }

    const handleHide = contextSafe(() => {
        gsap.to(refContainer.current, {
            scale: 0,
            opacity: 0,
            duration: 0.3,
            onComplete: () => setLoaded(true)
        })
    });

    useEffect(() => {
        if (isIntersecting && !loaded) {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => handleHide();
        }
    }, [imageUrl, isIntersecting, loaded, handleHide]);

    return (
        <button ref={ref} className='Game' onClick={handleSelect}>
            <div className='ImageWrapper'>
                {!loaded && (
                    <div ref={refContainer} className='PlaceholderContainer'>
                        <img src={img_placeholder} alt='placeholder' />
                        <span className='GameName'>{game.name}</span>
                    </div>
                )}
                {loaded && (
                    <div className='GameImageContainer'>
                        <img src={imageUrl} alt={game.name} className='GameImage' />
                        {game.fs && <img src={img_fs} alt='fs' className='GameFs' />}
                        {selectedId === game.id && <GamePlayButton game={game} sendOpenGame={sendOpenGame} isLoading={isLoading} />}
                    </div>
                )}
            </div>
        </button>
    )
}

export default Game;