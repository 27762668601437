import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { useTranslation } from 'react-i18next';
import { setTemporaryList } from 'features/main/MainSlice';
import { setGameCategory, setShowModal } from 'features/utility/UtilitySlice';
import AuthHeader from './authHeader/AuthHeader';
import LanguageButton from 'components/ui/languageButton/LanguageButton';
import img_logo from 'assets/images/logo/logo.png';
import './Header.scss';

export default function Header() {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const isLogin = useAppSelector((state: RootState) => state.main.isLogin);
    const { show } = useAppSelector((state: RootState) => state.utility.showModal);

    const handleShowModal = (type: string) => dispatch(setShowModal({ show: true, type: type }));
    const goToHomePage = () => {
        dispatch(setTemporaryList([]));
        dispatch(setGameCategory('home'));
        if (show) dispatch(setShowModal({ show: false, type: '' }));
    };

    return (
        <header className='Header'>

            <button className='LogoButton' onClick={goToHomePage}>
                <img src={img_logo} alt='logo' />
            </button>

            {!isLogin ? (
                <div className='ButtonsContainer'>
                    <button className='Register' onClick={() => handleShowModal('register')}>{t('header.register')}</button>
                    <button className='Login' onClick={() => handleShowModal('login')}>{t('header.login')}</button>
                    <LanguageButton />
                </div>
            ) : (
                <div>
                    <AuthHeader />
                </div>
            )}

        </header>
    )
}