import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { toast } from 'react-toastify';
import i18n from 'features/lang/i18n';

const { t } = i18n;

const deleteAndInsertItem = (array: any, action: any) => {
    let newArray = array.slice();
    newArray.pop();
    newArray.unshift(action);
    return newArray;
}
const insertItem = (array: any, action: any) => {
    let newArray = array.slice();
    newArray.unshift(action);
    return newArray;
}

export const initialState = {
    language: 'th',
    navigatorLanguage: '', // Browser locale
    gamesHistory: [],
    gamesFavorite: [],
    currentProvider: {
        show: true,
        provider: ''
    },
    jackpotNotification: {
        show: false,
        jackpot: {}
    },
    jackpotHistory: [],
    sound: false,
    credentials: {
        saved: false,
        username: '',
        password: '',
    },
    showModal: {
        show: false,
        type: ''
    },
    currentGame: {},
    gameCategory: ''
}

export const utilitySlice = createSlice({
    name: 'utility',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setNavigatorLanguage: (state, action) => {
            state.navigatorLanguage = action.payload;
        },
        setGamesHistory: (state: any, action) => {
            if (state.gamesHistory.some((el: any) => el.id === action.payload.id)) {
                for (let i = 0; i < state.gamesHistory.length; i++) {
                    if (state.gamesHistory[i].id === action.payload.id) {
                        return;
                    }
                }
            } else if (state.gamesHistory.length >= 20) {
                state.gamesHistory = deleteAndInsertItem(state.gamesHistory, action.payload);
            } else {
                state.gamesHistory = insertItem(state.gamesHistory, action.payload);
            }
        },
        setGamesFavorite: (state: any, action) => {
            if (state.gamesFavorite.some((el: any) => el.id === action.payload.id)) {
                for (let i = 0; i < state.gamesFavorite.length; i++) {
                    if (state.gamesFavorite[i].id === action.payload.id) {
                        state.gamesFavorite.splice(i, 1);
                        toast.info(`${action.payload.name} ${t('game.favorite_removed')}`);
                    }
                }
            } else {
                state.gamesFavorite = insertItem(state.gamesFavorite, action.payload);
                toast.success(`${action.payload.name} ${t('game.favorite_added')}`);
            }
        },
        setCurrentProvider: (state, action) => {
            state.currentProvider = {
                show: action.payload.show,
                provider: action.payload.provider
            }
        },
        setJackpotNotification: (state, action) => {
            state.jackpotNotification = {
                show: action.payload.show,
                jackpot: action.payload.jackpot
            }
        },
        setJackpotHistory: (state: any, action) => {
            if (state.jackpotHistory.some((el: any) => el.id === action.payload.id)) {
                for (let i = 0; i < state.jackpotHistory.length; i++) {
                    if (state.jackpotHistory[i].id === action.payload.id) {
                        state.jackpotHistory = state.jackpotHistory;
                    }
                }
            } else if (state.jackpotHistory.length >= 10) {
                state.jackpotHistory = deleteAndInsertItem(state.jackpotHistory, action.payload);
                state.jackpotNotification = {
                    show: true,
                    jackpot: action.payload
                }
            } else {
                state.jackpotHistory = insertItem(state.jackpotHistory, action.payload);
                state.jackpotNotification = {
                    show: true,
                    jackpot: action.payload
                }
            }
        },
        setSound: (state, action) => {
            state.sound = action.payload
        },
        saveCredentials: (state, action) => {
            state.credentials = {
                saved: action.payload.saved,
                username: action.payload.username,
                password: action.payload.password
            }
        },
        setShowModal: (state, action) => {
            state.showModal = {
                show: action.payload.show,
                type: action.payload.type
            }
        },
        setCurrentGame: (state, action) => {
            state.currentGame = action.payload
        },
        setGameCategory: (state, action) => {
            state.gameCategory = action.payload;
        }
    }
})

export const {
    setLanguage,
    setNavigatorLanguage,
    setGamesHistory,
    setGamesFavorite,
    setCurrentProvider,
    setJackpotNotification,
    setJackpotHistory,
    setSound,
    saveCredentials,
    setShowModal,
    setCurrentGame,
    setGameCategory
} = utilitySlice.actions;

export const utilitySelector = (state: RootState) => state.utility;

export default utilitySlice.reducer;