import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { sendRequest } from 'features/main/MainSlice';
import { RootState } from 'app/store';
import MainView from '../shared/MainView';
import { formatCash, isObjEmpty } from 'features/helpers/general';
import Spinner from 'components/ui/spinner/Spinner';
import { IoMdClose } from 'react-icons/io';
import '../shared/Shared.scss';
import './Transactions.scss';

const endDate = new Date().toISOString().slice(0, 10);

export default function Transaction(props: any) {
    const { handleClose } = props;
    const { t } = useTranslation();

    const [currentOperation, setCurrentOperation] = useState('in');
    const [date, setDate] = useState(0);

    const dispatch = useAppDispatch();
    const token = useAppSelector((state: RootState) => state.main.token);
    const isLoading = useAppSelector((state: RootState) => state.main.isLoading);
    const { currency } = useAppSelector((state: RootState) => state.main.userData);
    const language = useAppSelector((state: RootState) => state.utility.language);
    const transactionHistory = useAppSelector((state: RootState) => state.main.transactionHistory);

    const handlePress = (operation: string = 'in', days: number = 0): void => {
        setCurrentOperation(operation);
        setDate(days);

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - days);
        const startDate = currentDate.toISOString().slice(0, 10);

        dispatch(sendRequest({
            isLoading: true,
            route: 'transactionHistory',
            token: token,
            operation: operation,
            start: startDate,
            end: endDate
        }));
    }

    useEffect(() => {
        handlePress();
    }, []);

    return (
        <MainView>
            <div className='ProfilePassword'>
                <h3 className='ProfileTitle'>{t('transactionHistory.title')}</h3>
                <div className='ProfileContainer'>
                    <div className='TransactionsButtons'>
                        <button className={`Deposit ${currentOperation === 'in' ? 'Active' : ''}`} onClick={() => handlePress('in')}>{t('footer.deposit')}</button>
                        <button className={`Withdraw ${currentOperation === 'out' ? 'Active' : ''}`} onClick={() => handlePress('out')}>{t('footer.withdraw')}</button>
                    </div>

                    <div className='TransactionsButtonsHistory'>
                        <button onClick={() => handlePress(currentOperation, 0)} className={`${date === 0 ? 'TransactionsButtonsHistoryActive' : ''}`}>{t('gamesHistory.today')}</button>
                        <button onClick={() => handlePress(currentOperation, 1)} className={`${date === 1 ? 'TransactionsButtonsHistoryActive' : ''}`}>{t('gamesHistory.yesterday')}</button>
                        <button onClick={() => handlePress(currentOperation, 7)} className={`${date === 7 ? 'TransactionsButtonsHistoryActive' : ''}`}>{t('gamesHistory.lastWeek')}</button>
                    </div>

                    {isLoading ? (
                        <div className='LoadingContainer'>
                            <Spinner />
                        </div>
                    ) : (
                        <div>
                            {!isObjEmpty(transactionHistory) ? transactionHistory.map((transaction: any) => (
                                <div key={transaction.id} className='Transaction'>
                                    <div className='TransactionDetails'>
                                        <div>
                                            <p className='TransactionType'>{currentOperation === 'in' ? t('footer.deposit') : t('footer.withdraw')}</p>
                                            <p className='TransactionId'>ID: {transaction.id}</p>
                                            <span className='TransactionTime'>{transaction.datetime}</span>
                                        </div>
                                        <span className='TransactionCash'>{formatCash(language, currency, transaction.cash,)}</span>
                                    </div>
                                </div>
                            )) : (
                                <div className='TransactionsEmpty'>
                                    <p>{t('transactionHistory.empty')}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <button className='CloseButton' onClick={handleClose}>
                <IoMdClose />
            </button>
        </MainView>
    )
}