import { useTranslation } from 'react-i18next';
import MainView from '../shared/MainView';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { useEffect, useState } from 'react';
import { sendRequest } from 'features/main/MainSlice';
import Spinner from 'components/ui/spinner/Spinner';
import { isObjEmpty } from 'features/helpers/general';
import { IoMdClose } from 'react-icons/io';
import './History.scss';

const endDate = new Date().toISOString().slice(0, 10);

export default function History(props: any) {
    const { handleClose } = props;
    const { t } = useTranslation();

    const [date, setDate] = useState(0);

    const dispatch = useAppDispatch();
    const token = useAppSelector((state: RootState) => state.main.token);
    const gamesHistory = useAppSelector((state: RootState) => state.main.gamesHistory);
    const isLoading = useAppSelector((state: RootState) => state.main.isLoading);

    const selectDate = (days: number = 0) => {
        setDate(days);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - days);
        const startDate = currentDate.toISOString().slice(0, 10);

        dispatch(sendRequest({
            isLoading: true,
            route: 'gamesHistory',
            token: token,
            start: startDate,
            end: endDate
        }));
    }

    useEffect(() => {
        selectDate();
    }, []);

    return (
        <MainView>
            <div className='ProfilePassword'>
                <h3 className='ProfileTitle'>{t('gamesHistory.title')}</h3>
                <div className='ProfileContainer'>
                    <div className='TransactionsButtonsHistory'>
                        <button onClick={() => selectDate(0)} className={`${date === 0 ? 'TransactionsButtonsHistoryActive' : ''}`}>{t('gamesHistory.today')}</button>
                        <button onClick={() => selectDate(1)} className={`${date === 1 ? 'TransactionsButtonsHistoryActive' : ''}`}>{t('gamesHistory.yesterday')}</button>
                        <button onClick={() => selectDate(7)} className={`${date === 7 ? 'TransactionsButtonsHistoryActive' : ''}`}>{t('gamesHistory.lastWeek')}</button>
                    </div>
                    {isLoading ? (
                        <div className='LoadingContainer'>
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {!isObjEmpty(gamesHistory) ? gamesHistory.map((game: any) => (
                                <div key={game.id} className='HistoryGame'>
                                    <p className='HistoryGameTitle'>{game.game_name}</p>
                                    <p>{t('gamesHistory.sessionID')}: {game.id}</p>
                                    <p>{t('gamesHistory.start')}: {game.datetime_in}</p>
                                    <p>{t('gamesHistory.end')}: {game.datetime_out}</p>
                                </div>
                            )) : (
                                <div className='TransactionsEmpty'>
                                    <p>{t('transactionHistory.empty')}</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <button className='CloseButton' onClick={handleClose}>
                <IoMdClose />
            </button>
        </MainView>
    )
}