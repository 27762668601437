import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { useTranslation } from 'react-i18next';
import { formatCash } from 'features/helpers/general';
import { setShowModal } from 'features/utility/UtilitySlice';

import img_user from 'assets/images/header/login.png';
import img_user_icon from 'assets/images/header/user.png';
import img_coin from 'assets/images/header/coin.png';
import { AiOutlineLogout } from 'react-icons/ai';
import './AuthHeader.scss';
import LanguageButton from 'components/ui/languageButton/LanguageButton';

export default function AuthHeader() {

    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const dispatch = useAppDispatch();

    const { login, currency, cash }: any = useAppSelector((state: RootState) => state.main.userData);
    const language: string = useAppSelector((state: RootState) => state.utility.language);
    const showModalState = useAppSelector((state: RootState) => state.utility.showModal);

    const handleShowModal = (type: string): void => {
        if (showModalState.type !== type) {
            dispatch(setShowModal({
                show: true,
                type: type
            }));
        }
    }

    const showModal = () => dispatch(setShowModal({ show: true, type: 'logout' }));

    const historyButtons = [
        {
            type: 'password',
            text: t('header.password')
        },
        {
            type: 'gameHistory',
            text: t('header.game_history')
        },
        {
            type: 'transactionHistory',
            text: t('header.transaction_history')
        },
        {
            type: 'bonuses',
            text: t('header.bonuses')
        },
    ];

    const renderHistoryButtons = () => historyButtons.map((button, i) => (
        <button key={i} className={`Button ButtonHistory ${showModalState.type === button.type && 'ButtonActive'}`} onClick={() => handleShowModal(button.type)}>
            {button.text}
        </button>
    ))

    return (
        <div className='AuthHeader'>

            {/* Player Login */}
            <div className='UserContainer'>
                <div className='User'>
                    <img src={img_user} alt='user' className='UserImage' />
                    <span>{login}</span>
                </div>
                <button onClick={() => handleShowModal('profile')}>
                    <img src={img_user_icon} alt='user_icon' />
                    {t('header.profile')}
                </button>
                <LanguageButton />
                <button className='LogoutButton' onClick={showModal}>
                    <AiOutlineLogout className='LogoutIcon' />
                </button>
            </div>

            {/* Balance */}
            <div className='BalanceContainer'>

                {!isTabletOrMobile && renderHistoryButtons()}
                <button className='Button ButtonDeposit' onClick={() => handleShowModal('deposit')}>
                    {t('footer.deposit')}
                </button>
                <button className='Button ButtonWithdraw' onClick={() => handleShowModal('withdraw')}>
                    {t('footer.withdraw')}
                </button>
                <div className='Balance'>
                    <img src={img_coin} alt='user' className='CoinImage' />
                    <span>{formatCash(language, currency, cash)}</span>
                </div>
            </div>

            {isTabletOrMobile && (
                <div className='MobileHeaderButtons'>
                    {renderHistoryButtons()}
                </div>
            )}

        </div>
    )
}