import { useEffect } from 'react';
import { getUA } from 'react-device-detect';
import { Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { sendRequest } from 'features/main/MainSlice';
// import { setLanguage, setNavigatorLanguage } from 'features/utility/UtilitySlice';
import { useInterval } from 'usehooks-ts';
// import i18n from './features/lang/i18n';
import { ToastContainer } from 'react-toastify';
// import { getBrowserLocale, setLanguageCode } from 'features/helpers/general';
// import { config } from 'app/config';

import Preloader from 'components/hoc/preloader/Preloader';
import Home from 'components/Pages/Home';
import Game from 'components/Pages/Game';

import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';

gsap.registerPlugin(useGSAP);

function App() {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const dispatch = useAppDispatch();
  const isFirstTimeLoading = useAppSelector((state: RootState) => state.main.isFirstTimeLoading);
  const language = useAppSelector((state: RootState) => state.utility.language);
  const isLogin = useAppSelector((state: RootState) => state.main.isLogin);
  const token = useAppSelector((state: RootState) => state.main.token);
  const { wheels } = useAppSelector((state: RootState) => state.main.userData);
  const { isOpen } = useAppSelector((state: RootState) => state.main.game);
  const { show } = useAppSelector((state: RootState) => state.main.isError);

  // Dev
  // const state = useAppSelector((state: RootState) => state.main);
  // console.log(state)

  useEffect(() => {
    if (isFirstTimeLoading) {
      // const languageCode = setLanguageCode(true, 'th', i18n.resolvedLanguage, config.supportedLanguages);

      // Get Games List and User Info
      dispatch(sendRequest({
        route: 'init',
        language: language,
        userAgent: getUA,
        isMobile: isTabletOrMobile
      }));

      // Save language code
      // dispatch(setLanguage(languageCode));
      // dispatch(setNavigatorLanguage(getBrowserLocale()));
      // changeLanguage(languageCode);

    }
  }, []);

  // Get user data on interval
  useInterval(() => {
    if (isLogin) {
      dispatch(sendRequest({
        route: 'info',
        token: token,
        language: language
      }));
    }
  }, isOpen || wheels !== 0 ? null : 6000);

  return (
    <div className="App">

      {isFirstTimeLoading ? <Preloader /> : (
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/game' element={<Game />} />

        </Routes>
      )}

      <ToastContainer position='top-right' autoClose={5000} />
    </div>
  );
}

export default App;
