import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { setTemporaryList } from 'features/main/MainSlice';
import { setGameCategory, setShowModal } from 'features/utility/UtilitySlice';
import { handleLineRedirect } from 'components/hoc/lineMessenger/LineMessenger';

import img_home from 'assets/images/footer/home.png';
import img_deposit from 'assets/images/footer/deposit.png';
import img_withdraw from 'assets/images/footer/withdraw.png';
import img_chat from 'assets/images/footer/contact.png';
import img_logo from 'assets/images/footer/logo.png';
import img_center from 'assets/images/footer/center.png';
import img_bg from 'assets/images/footer/bg.png';

import './MobileFooter.scss';

export default function MobileFooter() {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const isLogin: boolean = useAppSelector((state: RootState) => state.main.isLogin);
    const { show } = useAppSelector((state: RootState) => state.utility.showModal);

    const handleShowModal = (type: string): void => {
        dispatch(setShowModal({
            show: true,
            type: isLogin ? type : 'login'
        }));
    }

    const handleCloseProfile = (): void => {
        dispatch(setTemporaryList([]));
        dispatch(setGameCategory('home'));
        if (show) dispatch(setShowModal({ show: false, type: '' }));
    }

    const menu = [
        {
            title: t('footer.home'),
            img: img_home,
            func: () => handleCloseProfile()
        },
        {
            title: t('footer.deposit'),
            img: img_deposit,
            func: () => handleShowModal('deposit')
        },
        {
            title: t('game.play'),
            center: true,
            func: () => handleCloseProfile()
        },
        {
            title: t('footer.withdraw'),
            img: img_withdraw,
            func: () => handleShowModal('withdraw')
        },
        {
            title: t('footer.chat'),
            img: img_chat,
            func: () => handleLineRedirect()
        },
    ];


    return (
        <ul className='MobileFooter'>
            {menu.map((button, i) => (
                button.center ? (
                    <li key={i} className='MobileButton MobileButtonCenter' onClick={button.func}>
                        <div className='MobileFooterCenterContainer'>
                            <img src={img_logo} alt={button.title} className='MobileFooterLogo' />
                            <img src={img_center} alt={button.title} className='MobileFooterLogoCenter' />
                        </div>
                        <span>{button.title}</span>
                    </li>
                ) : (
                    <li key={i} className='MobileButton' onClick={button.func}>
                        <img src={button.img} alt={button.title} className='MobileFooterImg' />
                        <span>{button.title}</span>
                    </li>
                )
            ))}
            <img src={img_bg} alt='menu_background' className='MobileFooterBackground' />
        </ul>
    )
}