import { useNavigate, useLocation } from 'react-router-dom';
import { getUA } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import Header from 'components/hoc/header/Header';
import MobileLayout from 'components/Layout/Mobile/MobileLayout';
import DesktopLayout from 'components/Layout/Desktop/DesktopLayout';
import MobileFooter from 'components/hoc/footer/mobile/MobileFooter';
import LineMessenger from 'components/hoc/lineMessenger/LineMessenger';
import Modal from 'components/ui/modal/Modal';
import { useEffect } from 'react';

export default function Home() {
    const navigate = useNavigate();
    const scrollYoffset = useLocation().state;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const showModal = useAppSelector((state: RootState) => state.utility.showModal);
    const game = useAppSelector((state: RootState) => state.main.game);
    const isLogin = useAppSelector((state: RootState) => state.main.isLogin);

    //Restore scroll Y position when navigating back from game page
    useEffect(() => {
        const timer = setTimeout(() => {
            if (scrollYoffset !== null && Number(scrollYoffset) > 0) {
                window.scrollTo(0, Number(scrollYoffset));
            } else {
                window.scrollTo(0, 0);
            }
        }, 100);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (game.isOpen && isLogin) {
            navigate('/game', {
                state: {
                    page: '/',
                    pageYOffset: window.pageYOffset
                }
            });
        }
    }, [game]);

    return (
        <>
            <Header />
            {isTabletOrMobile ? <MobileLayout /> : <DesktopLayout />}
            {isTabletOrMobile && isPortrait && <MobileFooter />}
            {showModal.show && <Modal userAgent={getUA} />}

            <LineMessenger />
        </>
    )
}