export const getBrowserLocale = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;

export const isObjEmpty = (obj: any) => {
    for (const key in obj) return false
    return true
}

export const delay = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
);

export function isStringInArray(value: string | undefined, array: string[]): boolean {
    return !!value && array.includes(value);
}

export function setLanguageCode(isHardCoded: boolean, hardCode: string, code: string | undefined, supportedLanguageArray: string[]): string {
    if (isHardCoded) {
        return hardCode;
    } else {
        return isStringInArray(code, supportedLanguageArray) && code ? code : hardCode;
    }
}

export function arePasswordsMatching(password: string, confirmPassword: string): boolean {
    return password === confirmPassword;
}

export function combinedArray(arrayIds: any, arrayGames: any) {
    const combinedArray = [];
    for (let obj of arrayGames) {
        if (arrayIds.includes(obj.id)) {
            combinedArray.push(obj);
        }
    }
    return combinedArray;
}

export function transformText(text: string) {
    return text.split('_').map(word => word.toUpperCase()).join(' ');
}

function isValidObject(obj: any): boolean {
    return obj && typeof obj === 'object' && Object.keys(obj).length > 0;
}

export function combineObjectKeys(...objects: any[]): string[] {
    const keysArray = objects.reduce((acc, obj) => {
        if (isValidObject(obj)) {
            acc.push(...Object.keys(obj));
        }
        return acc;
    }, [] as string[]);

    return Array.from(new Set(keysArray));
}


export function formatCash(language: string, currency: string, cash: number) {
    return Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(cash);
}

export function removeSuffix(str: string): string {
    const suffixes = ['OP', 'SL', 'SR', 'EV'];
    const regex = new RegExp(`\\s?(${suffixes.join('|')})$`);
    return str.replace(regex, '');
}