import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { setShowModal } from 'features/utility/UtilitySlice';
import MobileLoginForm from './mobile/MobileLoginForm';
import './LoginForm.scss';
import DesktopLoginForm from './desktop/DesktopLoginForm';

export default function LoginForm(props: any) {

    const { userAgent, handleClose } = props;

    const dispatch = useAppDispatch();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const showModal = useAppSelector((state: RootState) => state.utility.showModal);

    const selectForm = (type: string) => dispatch(setShowModal({ show: true, type: type }));

    return (
        <div className='LoginForm'>
            {isTabletOrMobile ? (
                <MobileLoginForm showModal={showModal} closeForm={handleClose} userAgent={userAgent} />
            ) : (
                <DesktopLoginForm showModal={showModal} selectForm={selectForm} closeForm={handleClose} userAgent={userAgent} />
            )}
        </div>
    )
}