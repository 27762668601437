import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import './GamesList.scss';
import Game from './Game/Game';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { sendRequest } from 'features/main/MainSlice';
import { getUA } from 'react-device-detect';
import { setCurrentGame, setGamesHistory, setShowModal } from 'features/utility/UtilitySlice';

export default function GamesList(props: any) {
    const { games } = props;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const dispatch = useAppDispatch();
    const isLogin = useAppSelector((state: RootState) => state.main.isLogin);
    const token = useAppSelector((state: RootState) => state.main.token);
    const language = useAppSelector((state: RootState) => state.utility.language);
    const isLoading = useAppSelector((state: RootState) => state.main.isLoading);

    const [selectedId, setSelectedId] = useState(0);

    const sendOpenGame = ({ game }: any) => {
        if (isLogin) {
            dispatch(sendRequest({
                isLoading: true,
                route: 'open',
                token: token,
                gameId: game.id,
                demo: '0',
                language: language,
                userAgent: getUA,
                isMobile: isTabletOrMobile
            }));
            dispatch(setGamesHistory(game));
            dispatch(setCurrentGame(game));
        } else {
            dispatch(setShowModal({
                show: true,
                type: 'login'
            }));
        }
    }

    return (
        <div className='GamesList'>
            {games.map((game: any) => (
                <Game
                    key={game.id}
                    game={game}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    sendOpenGame={sendOpenGame}
                    isLoading={isLoading}
                />
            ))}
        </div>
    )
}