export const config: any = {
    supportedLanguages: ['th', 'en'],
    payments: {
        deposit: {
            "id": "205",
            "fields": [
                {
                    "name": "amount",
                    "title": "Amount",
                    "type": "float",
                    "min": "300.00",
                    "max": "50000.00"
                }
            ]
        },
        withdraw: {
            "id": "206",
            "fields": [
                {
                    "name": "bankCardNum",
                    "title": "Bank account number",
                    "type": "string",
                    "max_length": 128,
                    "value": ""
                },
                {
                    "name": "bankUserName",
                    "title": "Account holder name",
                    "type": "string",
                    "max_length": 128,
                    "value": ""
                },
                {
                    "name": "bankName",
                    "title": "Bank Name",
                    "type": "select",
                    "options": {
                        "KBANK": "Kasikorn Bank",
                        "SCB": "SCB",
                        "BBL": "Bangkok Bank",
                        "KTB": "KTB",
                        "TTB": "TMB THANACHART BANK",
                        "CITI": "CITIBANK",
                        "SCBT": "STANDARD CHARTERED BANK (THAI)",
                        "CIMB": "CIMB THAI BANK",
                        "UOBT": "UNITED OVERSEAS BANK (THAI)",
                        "BAY": "BANK OF AYUDHYA",
                        "GOV": "GOVERNMENT SAVINGS BANK",
                        "GHB": "THE GOVERNMENT HOUSING BANK",
                        "BAAC": "BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES",
                        "BOC": "BANK OF CHINA (THAI)",
                        "ISBT": "ISLAMIC BANK OF THAILAND",
                        "KK": "KIATNAKIN PHATRA BANK",
                        "ICBC": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI)",
                        "LHBANK": "LAND AND HOUSES BANK"
                    }
                },
                {
                    "name": "amount",
                    "title": "Amount",
                    "type": "float",
                    "min": "300.00",
                    "max": "50000.00"
                }
            ]
        }
    }
}