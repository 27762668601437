import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import { setGamesFavorite } from 'features/utility/UtilitySlice';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import img_logo from 'assets/images/logo/logo.png';
import './GameControls.scss';

interface FavoriteGame {
    id: number
}

export default function GameControls(props: any) {
    const { game, isIOS, toggleFullscreen, isFullscreen, handleClose, isLandscape } = props;

    const dispatch = useAppDispatch();
    const gamesFavorite: FavoriteGame[] = useAppSelector((state: RootState) => state.utility.gamesFavorite);
    const currentGame: any = useAppSelector((state: RootState) => state.utility.currentGame);

    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const setFavorite = (): void => {
        dispatch(setGamesFavorite(currentGame));
    }

    useEffect(() => {
        const checkIsFavorite = gamesFavorite.some(fav => fav.id === currentGame.id);
        setIsFavorite(checkIsFavorite);
    }, [gamesFavorite]);

    return (
        <div className={`GameControls ${isLandscape ? 'GameControlsLandscape' : ''}`}>
            <div className='GameControlsName'>
                <img src={img_logo} alt='logo' className='GameControlsNameLogo' />
                {!isLandscape && <p>{game.name}</p>}
            </div>
            <div className='Buttons'>
                <button className='Button Favorite' onClick={setFavorite}>
                    {isFavorite ? <MdFavorite className='GameControlsIcon' /> : <MdFavoriteBorder className='GameControlsIcon' />}
                </button>

                {!isIOS && (
                    <button className='Button Fullscreen' onClick={toggleFullscreen}>
                        {isFullscreen ? (
                            <AiOutlineFullscreenExit className='GameControlsIcon' />
                        ) : (
                            <AiOutlineFullscreen className='GameControlsIcon' />
                        )}
                    </button>
                )}

                <button className='Button CloseButtonGame' onClick={handleClose}>
                    <IoMdClose className='GameControlsIcon' />
                </button>
            </div>
        </div>
    )
}