import { useMobileOrientation } from 'react-device-detect';
import img_line from 'assets/images/footer/line.png';
import './LineMessenger.scss';

export const handleLineRedirect = () => { // TODO
    const url = 'https://line.me/R/nv/chat';
    window.location.href = url;
}

export default function LineMessenger() {
    const { isLandscape } = useMobileOrientation();

    return (
        <button className={`ButtonLine ${isLandscape && 'ButtonLineLanscape'}`} onClick={handleLineRedirect}>
            <img src={img_line} alt='Line' className='LineIcon' />
        </button>
    )
}