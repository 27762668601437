
import Slider from './slider/Slider';
import News from './news/News';
import './BannerNewsSection.scss';

export default function BannerNewsSection() {
    return (
        <section className='BannerNewsSection'>
            <Slider />
            <News />
        </section>
    )
}