import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'app/hooks';
import { logout } from 'features/main/MainSlice';
import './Logout.scss';

export default function Logout(props: any) {

    const { handleClose } = props;

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const handleConfirm = () => {
        dispatch(logout());
        handleClose();
    }

    return (
        <div className='LogoutModal'>
            <h4>{t('general.logout_question')}</h4>
            <div>
                <button className='Cancel' onClick={handleClose}>{t('game.cancel')}</button>
                <button className='Confirm' onClick={handleConfirm}>{t('game.confirm')}</button>
            </div>
        </div>
    )
}