import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { useTranslation } from 'react-i18next';
import mobile_bg_img from 'assets/images/auth/bg.jpg';

import AuthForm from '../inputForm/AuthForm';
import img_logo_1 from 'assets/images/logo/animation/1.png';
import img_logo_2 from 'assets/images/logo/animation/2.png';
import img_logo_3 from 'assets/images/logo/animation/3.png';
import img_logo_4 from 'assets/images/logo/animation/4.png';

import close_icon from 'assets/images/icons/close.svg';

import './MobileLoginForm.scss';

export default function MobileLoginForm(props: any) {

    const { showModal, closeForm, userAgent } = props;

    const { t } = useTranslation();

    useGSAP(() => {
        let tl = gsap.timeline();
        tl.from('.MobileLoginFormLogoMain', { scale: 0.2, duration: 1, ease: 'bounce.out' })
        tl.from('.Lighting1', { opacity: 0, duration: 0.5, ease: 'bounce.out' })
        tl.from('.Lighting2', { opacity: 0, duration: 0.5, ease: 'bounce.out' })
        tl.to('.Lighting1', { opacity: 0, duration: 0.5, ease: 'bounce.out' })
        tl.from('.Lighting3', { opacity: 0, duration: 0.5, ease: 'bounce.out' })
        tl.to('.Lighting2', { opacity: 0, duration: 0.5, ease: 'bounce.out' })
        tl.to('.Lighting3', { opacity: 0, duration: 0.5, ease: 'bounce.out' })
    });

    return (
        <div className='MobileLoginFormContainer' style={{ backgroundImage: `url(${mobile_bg_img})` }}>
            <button className='MobileLoginFormCloseButton' onClick={closeForm}>
                <img src={close_icon} alt='close' className='MobileLoginFormCloseButtonIcon' />
            </button>

            <div className='MobileLoginFormLogoContainer'>
                <img src={img_logo_1} alt='logo' className='MobileLoginFormLogo MobileLoginFormLogoMain' />
                <img src={img_logo_2} alt='logo' className='MobileLoginFormLogo Lighting1' />
                <img src={img_logo_3} alt='logo' className='MobileLoginFormLogo Lighting2' />
                <img src={img_logo_4} alt='logo' className='MobileLoginFormLogo Lighting3' />
            </div>

            <AuthForm userAgent={userAgent} />

            <div className='FormToggle'>
                <span>{t('header.not_member')}</span>
                <button>
                    {t('header.register')}
                </button>
            </div>

        </div>
    )
}