import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import MainView from '../shared/MainView';
import { sendRequest } from 'features/main/MainSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatCash } from 'features/helpers/general';
import Spinner from 'components/ui/spinner/Spinner';
import { IoMdClose } from 'react-icons/io';
import '../shared/Shared.scss';
import './Deposit.scss';

import { config } from 'app/config';
const { payments } = config;

export default function Deposit(props: any) {
    const { handleClose } = props;

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const token: string = useAppSelector((state: RootState) => state.main.token);
    const language = useAppSelector((state: RootState) => state.utility.language);
    const { currency }: any = useAppSelector((state: RootState) => state.main.userData);
    const isLoading: boolean = useAppSelector((state: RootState) => state.main.isLoading);

    const validationSchema = Yup.object({
        amount: Yup.number()
            .min(300, t('payment.less_than'))
            .max(50000, t('payment.greater_than'))
            .required(t('payment.empty'))
    })

    const formik = useFormik({
        initialValues: {
            amount: '300.00',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(sendRequest({
                isLoading: true,
                route: 'paymentRequest',
                token: token,
                operation: 'in',
                provider: payments.deposit.id,
                fields: {
                    amount: values.amount
                }
            }));
        },
    });


    return (
        <MainView>

            <div className='ProfileMain'>

                <h3 className='ProfileTitle'>{t('footer.deposit')}</h3>

                <div className='ProfileContainer'>

                    {payments.deposit.fields.map((field: any, i: any) => (
                        <Fragment key={i}>
                            <p className='PaymentTitle'>{t('payment.title')}</p>

                            <div className='FormContainer'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='AmountContainer'>
                                        <span>{t([`payment.${field.name}`])}</span>
                                        <input
                                            name='amount'
                                            type='number'
                                            onChange={formik.handleChange}
                                            value={formik.values.amount}
                                            onBlur={formik.handleBlur}
                                            className={`${formik.touched.amount && formik.errors.amount ? 'AmountError' : ''}`}
                                        />
                                    </div>
                                    {formik.touched.amount && formik.errors.amount ? (
                                        <p className='DepositError'>{formik.errors.amount}</p>
                                    ) : null}
                                    <div className='AmountRequirements'>
                                        <span>{t('payment.min')}:</span>
                                        <span>{formatCash(language, currency, field.min)}</span>
                                    </div>
                                    <div className='AmountRequirements'>
                                        <span>{t('payment.max')}:</span>
                                        <span>{formatCash(language, currency, field.max)}</span>
                                    </div>
                                    <button type='submit' disabled={isLoading ? true : false}>
                                        {isLoading ? <Spinner /> : t('footer.deposit')}
                                    </button>
                                </form>
                            </div>
                        </Fragment>
                    ))}

                </div>

                <button className='CloseButton' onClick={handleClose}>
                    <IoMdClose />
                </button>

            </div>

        </MainView>
    )
}