import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

import img_logo from 'assets/images/logo/logo.png';
import './Preloader.scss';

export default function Preloader() {

    useGSAP(() => {
        gsap.to('.Ring', {
            scale: 3,
            opacity: 0,
            duration: 2,
            stagger: {
                each: 0.3,
                repeat: -1
            }
        });

        gsap.to('.Logo', {
            scale: 1.05,
            duration: 0.5,
            stagger: {
                repeat: -1,
                yoyo: true
            }
        });
    });

    return (
        <div className='Preloader'>
            <div className='Ring' />
            <div className='Ring' />
            <div className='Ring' />
            <div className='Ring' />
            <img src={img_logo} className='Logo' alt='logo' />
        </div>
    )
}