import { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { setShowModal } from 'features/utility/UtilitySlice';
import Main from './main/Main';
import Password from './password/Password';
import './Profile.scss';
import Deposit from './deposit/Deposit';
import Withdraw from './withdraw/Withdraw';
import Bonuses from './bonuses/Bonuses';
import History from './history/History';
import Transaction from './transactions/Transactions';

export default function Profile(props: any) {

    const { handleClose, type, handleModalType, isLogin } = props;

    const dispatch = useAppDispatch();

    const profileType = (type: string) => {
        switch (type) {
            case 'deposit':
                return <Deposit handleClose={handleClose} />
            case 'withdraw':
                return <Withdraw handleClose={handleClose} />
            case 'gameHistory':
                return <History handleClose={handleClose} />
            case 'transactionHistory':
                return <Transaction handleClose={handleClose} />
            case 'bonuses':
                return <Bonuses handleClose={handleClose} />
            case 'password':
                return <Password handleClose={handleClose} handleModalType={handleModalType} />
            default:
                return <Main handleClose={handleClose} handleModalType={handleModalType} />
        }
    }

    useEffect(() => {
        if (!isLogin) {
            dispatch(setShowModal({
                show: true,
                type: 'login'
            }));
        }
    }, [isLogin]);

    return (
        <div className='Profile'>
            {profileType(type)}
        </div>
    )
};