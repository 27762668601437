import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { isObjEmpty } from 'features/helpers/general';
import Labels from './components/Labels/Labels';
import GamesList from './components/GamesList/GamesList';
import { RootState } from 'app/store';
import { matchGameIds } from 'features/helpers/gamesList';
import { setTemporaryList } from 'features/main/MainSlice';
import './Games.scss';

export default function Games() {

    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const games: any = useAppSelector((state: RootState) => state.main.games);
    const temporaryList: any = useAppSelector((state: RootState) => state.main.temporaryList);

    const { labels } = games;

    const sortTemporaryList = (label: string) => {
        dispatch(setTemporaryList(matchGameIds(labels[label]['ids'], games.games)));
        window.scrollTo({
            top: ref.current?.offsetTop,
            behavior: 'smooth'
        });
    }

    useEffect(() => { }, [temporaryList]);

    return (
        <div ref={ref} className='GamesSection'>

            {isObjEmpty(temporaryList) ? (
                <Labels labels={labels} sortTemporaryList={sortTemporaryList} />
            ) : (
                <GamesList games={temporaryList} />
            )}

        </div>
    )
}