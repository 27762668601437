import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
import SwiperCore from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

import banner_1 from 'assets/images/banners/banner1.png';
import banner_2 from 'assets/images/banners/banner2.png';
import banner_3 from 'assets/images/banners/banner3.png';
import banner_4 from 'assets/images/banners/banner4.png';

import banner_small_1 from 'assets/images/banners/smallBanner1.png';
import banner_small_2 from 'assets/images/banners/smallBanner2.png';
import banner_small_3 from 'assets/images/banners/smallBanner3.png';
import banner_small_4 from 'assets/images/banners/smallBanner4.png';

import './Slider.scss';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Autoplay]);

const banners = [
    {
        pc: banner_1,
        mobile: banner_small_1
    },
    {
        pc: banner_2,
        mobile: banner_small_2
    },
    {
        pc: banner_3,
        mobile: banner_small_3
    },
    {
        pc: banner_4,
        mobile: banner_small_4
    },
];

export default function Slider() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1001px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const BannerRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    return (
        <div className='Slider' ref={BannerRef} data-visible={visible}>
            <Swiper
                slidesPerView={1}
                spaceBetween={40}
                speed={700}
                loop={true}
                autoplay={{ delay: 4000, pauseOnMouseEnter: true, disableOnInteraction: false }}
                onAfterInit={() => setVisible(true)}
                centeredSlides={true}
                style={{ width: '100%', maxWidth: '1130px', overflow: 'unset' }}
            >
                {banners.map((banner, i) => {
                    return (
                        <SwiperSlide key={i} className='BannerSlider'>
                            <div className='Banner' data-mobile={isPortrait}>
                                <img
                                    className='BannerImage'
                                    src={isTabletOrMobile && isPortrait ? banner.mobile : banner.pc}
                                    alt='banner'
                                />
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}