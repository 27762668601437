import BannerNewsSection from 'components/BannerNewsSection/BannerNewsSection';
import GameCategories from 'components/GameCategories/GameCategories';
import Games from 'components/Games/Games';

import './MobileLayout.scss';

export default function MobileLayout() {

    return (
        <section>
            <BannerNewsSection />
            <div className='MobileMain'>
                <GameCategories />
                <Games />
            </div>
        </section>
    )
}